import React from 'react';
import './App.css';
import './assets/style/reset.css'
import {Game} from "./components/game/Game";

function App() {
    return (
        <div className="App">
            <Game/>
        </div>
    );
}

export default App;
