import style from './GameStyle.module.css';
import crystal from '../../assets/imgs/chrystall.svg';
import logo from '../../assets/imgs/aviator-logo.cafbd29233306bf7.svg';
import {GameField} from "../gameField/GameField";
import {useEffect, useRef, useState} from "react";
import {Modal} from "../modal/Modal";
import axios from "axios";
import {CreateGameType, GetBalanceType} from "../types/types";
import {History} from "../history/History";
import {useTranslation} from "react-i18next";
import {ReactComponent as Dropdown} from "../../assets/imgs/dropdown.svg";


export const Game = () => {

    const [bet, setBet] = useState(false)
    const [bet2, setBet2] = useState(false)
    const [disabledBetBtn, setDisabledBetBtn] = useState(false)
    const [disabledBetBtn2, setDisabledBetBtn2] = useState(false)
    const [disabledRateBtn, setDisabledRateBtn] = useState(false)
    const [disabledRateBtn2, setDisabledRateBtn2] = useState(false)
    const [conclusionBetBtn, setConclusionBetBtn] = useState(false)
    const [conclusionBetBtn2, setConclusionBetBtn2] = useState(false)
    const [betValue, setBetValue] = useState(10)
    const [betValue2, setBetValue2] = useState(10)
    const [openWinModal, setOpenWinModal] = useState(false)
    const [openWinModal2, setOpenWinModal2] = useState(false)
    const [data, setData] = useState<GetBalanceType>()
    const [balanceChanging, setBalanceChanging] = useState<any>()
    const [balanceChanging2, setBalanceChanging2] = useState<any>()
    const [stopGame, setStopGame] = useState(false)
    const [balance, setBalance] = useState<number>(0)
    const [balance2, setBalance2] = useState<number>(0)
    const [progress, setProgress] = useState(264);
    const [roadMove, setRoadMove] = useState(false)
    const [startFly, setStartFly] = useState(false)
    const [newGameData, setNewGameData] = useState<CreateGameType>()
    const [valueX, setValueX] = useState('')
    const [valueX2, setValueX2] = useState('')
    const [flyMen, setFlyMen] = useState(false)
    const [history, setHistory] = useState(false)
    const [takenMultiplier, setTakenMultiplier] = useState<number>(0)
    const [takenMultiplier2, setTakenMultiplier2] = useState<number>(0)
    const searchParams = new URLSearchParams(window.location.search)
    const id = searchParams.get("id")
    // const id = '1234'
    const lang = searchParams.get("lang") || "ru"

    const takenRef = useRef(false)
    const takenRef2 = useRef(false)

    const {t, i18n} = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang, i18n]);

    useEffect(() => {
        axios.get<GetBalanceType>(process.env.REACT_APP_API_URL + `/get_balance/${id}`)
            .then(res => {
                // setData({...res.data, history: res.data.history.map(({multiplier})=>({multiplier, id: v4()}))})
                setData(res.data)

                if (res.data.balance) {
                    setBalance(res.data.balance)
                    setBalance2(res.data.balance)
                }
            }).catch(rej => {
            // ('catch get balance')
        })
    }, [stopGame])

    const intervalId = useRef<NodeJS.Timer | null>(null)
    let time = 0;


    function countdown_start(newGameData: CreateGameType) {
        if (intervalId.current && stopGame) {
            clearInterval(intervalId.current)
        }
        if (balance && bet) {
            if (bet2) {
                setBalance(balance - betValue - betValue2)
            } else {
                setBalance(balance - betValue)
            }
        }

        // if (balance2 && bet2)
        //     setBalance2(balance2 - betValue2)

        intervalId.current = setInterval(() => {
            if (!newGameData)
                return
            let current_x = 0;
            const target_x = newGameData.multiplier;
            const variable_coeff1 = newGameData.first_coefficient;
            const variable_coeff2 = newGameData.second_coefficient;
            current_x = 1 + (target_x * time) / (variable_coeff1 + variable_coeff2 * Math.sqrt(target_x))

            setValueX(current_x.toFixed(2))

            setValueX2(current_x.toFixed(2))


            if (bet && !takenRef.current) {
                // ('setBalanceChanging1')
                const outPutValue = +(current_x * betValue).toFixed(1)
                setBalanceChanging(outPutValue)
            }
            if (bet2 && !takenRef2.current) {
                // ('setBalanceChanging2')
                const outPutValue2 = +(current_x * betValue2).toFixed(1)
                setBalanceChanging2(outPutValue2)
            }
            if (+current_x.toFixed(2) >= target_x) {
                intervalId.current && clearInterval(intervalId.current)
                intervalId.current = null
                setFlyMen(true)
                if (bet && !takenRef.current) {
                    addBalance(0, -betValue)
                }
                if (bet2 && !takenRef2.current) {
                    addBalance2(0, -betValue2)
                }
                setStopGame(true)
                return;
            }
            time += 0.1
        }, 100)
    }

    useEffect(() => {
        if (stopGame) {
            intervalId.current && clearInterval(intervalId.current)
            intervalId.current = null
            setRoadMove(false)

            setOpenWinModal(false)
            setOpenWinModal2(false)

            setTimeout(() => {
                takenRef.current = false
                takenRef2.current = false
                setStartFly(false)
                setProgress(264)
                setStopGame(false)
                setValueX('')
                setValueX2('')
                setFlyMen(false)
                setBet(false)
                setBet2(false)
                setDisabledBetBtn(false)
                setDisabledBetBtn2(false)
                setDisabledRateBtn(false)
                setDisabledRateBtn2(false)
                setBetValue(10)
                setBetValue2(10)
                setConclusionBetBtn(false)
                setConclusionBetBtn2(false)
            }, 4000)
        }

    }, [stopGame, setStopGame])


    const createNewGame = () => {
        axios.get(process.env.REACT_APP_API_URL + `/new_game/${id}`)
            .then(res => {
                setNewGameData(res.data)
                countdown_start(res.data)
            }).catch(rej => {

        })
    }
    const addBalance = (multiplier: number, balance_change: number | undefined | null) => {
        axios.post(process.env.REACT_APP_API_URL + `/add_balance`, {
            user_id: id,
            balance_change_1: balance_change,
            multiplier: multiplier
        }).then(res => {
            // (res.data)
        })
    }
    const addBalance2 = (multiplier: number, balance_change: number | undefined | null) => {
        axios.post(process.env.REACT_APP_API_URL + `/add_balance`, {
            user_id: id,
            balance_change_2: balance_change,
            multiplier: multiplier
        }).then(res => {
            // (res.data)
        })
    }

    const makeBet = () => {
        if (!conclusionBetBtn) {
            setBet(!bet)
        }
        if (conclusionBetBtn && bet && !stopGame) {
            setOpenWinModal(true)
            const difference = balanceChanging - betValue
            setTakenMultiplier(+valueX)
            takenRef.current = true
            addBalance(+valueX, difference)
            setDisabledBetBtn(true)
            if (balanceChanging)
                setBalance(balance + balanceChanging)
        }
    }
    const makeBet2 = () => {
        if (!conclusionBetBtn2) {
            // ('!conclusionBetBtn2')
            setBet2(!bet2)
        }
        if (conclusionBetBtn2 && bet2 && !stopGame) {
            // ('bet2')
            setOpenWinModal2(true)
            const difference2 = balanceChanging2 - betValue2
            setTakenMultiplier2(+valueX2)
            takenRef2.current = true
            addBalance2(+valueX2, difference2)
            setDisabledBetBtn2(true)
            if (balanceChanging2)
                setBalance2(balance2 + balanceChanging2)
        }
    }

    let b = 0
    if (data?.balance) {
        b = data.balance
    }
    const addRate = (idBtn: string) => {
        switch (idBtn) {
            case '1':
                if (betValue < b) {
                    setDisabledBetBtn(false)
                    return setBetValue(10)
                }
                return
            case '0.1':
                if (betValue < b) {
                    setDisabledBetBtn(false)
                    return setBetValue(val => val + 1)
                }
                return
            case '-0.1':
                if (betValue > 0.2 && betValue < b) {
                    return setBetValue(val => val - 1)
                }
                return
            case '2':
                if (betValue < b) {
                    setDisabledBetBtn(false)
                    return setBetValue(25)
                }
                return
            case '5':
                if (betValue < b) {
                    setDisabledBetBtn(false)
                    return setBetValue(50)
                }
                return
            case '10':
                if (betValue + 10 <= b) {
                    setDisabledBetBtn(false)
                    return setBetValue(100)
                }
                return
        }
    }

    const addRate2 = (idBtn: string) => {
        switch (idBtn) {
            case '1':
                if (betValue2 < b) {
                    setDisabledBetBtn2(false)
                    return setBetValue2(10)
                }
                return
            case '0.1':

                if (betValue2 < b) {
                    setDisabledBetBtn2(false)
                    return setBetValue2(val => val + 1)
                }
                return
            case '-0.1':
                if (betValue2 > 0.2 && betValue2 < b) {

                    return setBetValue2(val => val - 1)
                }
                return
            case '2':
                if (betValue2 < b) {

                    setDisabledBetBtn2(false)
                    return setBetValue2(25)
                }
                return
            case '5':
                if (betValue2 < b) {
                    setDisabledBetBtn2(false)
                    return setBetValue2(50)
                }
                return
            case '10':
                if (betValue2 + 100 <= b) {
                    setDisabledBetBtn2(false)
                    return setBetValue2(100)
                }
                return
        }
    }


    useEffect(() => {
        let int: any;
        let int2: any;

        if (openWinModal) {
            int = setTimeout(() => {
                setOpenWinModal(false)
            }, 5000)
        }
        if (openWinModal2) {
            int2 = setTimeout(() => {
                setOpenWinModal2(false)
            }, 3000)
        }
        return () => {
            clearTimeout(int)
            clearTimeout(int2)
        }
    }, [openWinModal, openWinModal2, stopGame])

    return (
        <>
            {history ? <History setHistory={setHistory} history={history}/>
                : <div className={style.Wrapper}>
                    <div className={style.ContainerForModals}>
                        {openWinModal && <Modal setOpenWinModal={setOpenWinModal}
                                                takenMultiplier={takenMultiplier}
                                                balanceChanging={balanceChanging}
                                                type={'1'}
                                                link={data?.ref_link}/>}

                        {openWinModal2 && <Modal setOpenWinModal={setOpenWinModal2}
                                                 takenMultiplier={takenMultiplier2}
                                                 balanceChanging={balanceChanging2}
                                                 type={'2'}
                                                 link={data?.ref_link}/>}
                    </div>

                    <header>
                        <div className={style.HeaderTopBlock}>
                            <img src={logo} alt="" className={style.logo_jet}/>
                            <div className={style.BalanceBlock}>
                                <p>{Math.round(balance)}</p>
                                <span>USDT</span>
                            </div>
                        </div>
                        <div className={style.HeaderBottomBlock}>
                            <div className={style.HistoryContainer}>
                                {
                                    data?.history.map((val, i) => {
                                        const multiplier = +val.multiplier.toFixed(1)

                                        return <button
                                            key={`${multiplier}${i}`}
                                            className={`${style.Inc} ${multiplier <= 2 && style.IncBlue} 
                                            ${multiplier > 2 && multiplier <= 9 && style.IncPurple}
                                            ${multiplier > 9 && multiplier <= 200 && style.IncOrange}`}>
                                            {multiplier}x
                                        </button>
                                    })
                                }
                            </div>
                        </div>
                    </header>
                    <div className={style.ContainerPaddings}>
                        <GameField disabledBetBtn={disabledBetBtn}
                                   disabledBetBtn2={disabledBetBtn2}
                                   bet={bet}
                                   bet2={bet2}
                                   setDisabledBetBtn={setDisabledBetBtn}
                                   setDisabledBetBtn2={setDisabledBetBtn2}
                                   setDisabledRateBtn={setDisabledRateBtn}
                                   setDisabledRateBtn2={setDisabledRateBtn2}
                                   setConclusionBetBtn={setConclusionBetBtn}
                                   setConclusionBetBtn2={setConclusionBetBtn2}
                                   betValue={betValue}
                                   addBalance={addBalance}
                                   stopGame={stopGame}
                                   setStopGame={setStopGame}
                                   setBalance={setBalance}
                                   balance={balance}
                                   setOpenWinModal={setOpenWinModal}
                                   openWinModal={openWinModal}
                                   countdown_start={countdown_start}
                                   roadMove={roadMove}
                                   setRoadMove={setRoadMove}
                                   startFly={startFly}
                                   setStartFly={setStartFly}
                                   progress={progress}
                                   setProgress={setProgress}
                                   valueX={valueX}
                                   flyMen={flyMen}
                                   createNewGame={createNewGame}
                        />
                        <div
                            className={`${style.Rate_blockContainer} ${bet && style.ErrRateBlock} ${conclusionBetBtn && style.ConcRateBlock}`}>
                            <div className={style.ModeRateBlock}>
                                <div className={style.Mode}>Bet</div>
                                <div className={style.Mode}>Auto</div>
                            </div>
                            <div className={style.Rate_block}>
                                <div className={style.Rate_counter_block}>
                                    <div className={style.Rate_counter_top}>
                                        <button className={style.Btn_dec}
                                                disabled={bet || disabledRateBtn}
                                                onClick={() => addRate('-0.1')}>-
                                        </button>
                                        <div
                                            className={`${style.Count_value_block} ${(bet || disabledRateBtn || disabledBetBtn) && style.Count_valueDisabled}`}>
                                             <span className={`${style.Count_value} `}>
                                                 {betValue}
                                             </span>
                                        </div>
                                        <button className={style.Btn_inc}
                                                disabled={bet || disabledRateBtn}
                                                onClick={() => addRate('0.1')}>
                                        </button>
                                    </div>
                                    <div className={style.Rate_counter_bottom}>
                                        <button className={style.Btn_add_rate} id="btn50"
                                                disabled={bet || disabledRateBtn}
                                                onClick={() => addRate('1')}>10
                                        </button>
                                        <button className={style.Btn_add_rate} id="btn100"
                                                disabled={bet || disabledRateBtn}
                                                onClick={() => addRate('2')}>25
                                        </button>
                                        <button className={style.Btn_add_rate} id="btn250"
                                                disabled={bet || disabledRateBtn}
                                                onClick={() => addRate('5')}>50
                                        </button>
                                        <button className={style.Btn_add_rate} id="max"
                                                disabled={bet || disabledRateBtn}
                                                onClick={() => addRate('10')}>100
                                        </button>
                                    </div>
                                </div>
                                <div className={style.Rate_block_btn_wrapper}>
                                    <button className={`${style.Rate_block_btn} 
                                         ${bet && style.Rate_block_btn_cancelled} 
                                         ${conclusionBetBtn && style.Rate_block_btn_conclusion}
                                         ${(stopGame || disabledBetBtn) && style.DisabledBtn}`}
                                            disabled={disabledBetBtn}
                                            onClick={makeBet}>
                                        {bet && !conclusionBetBtn && <span>{t("cancel")}</span>}
                                        {!bet &&
                                            <>
                                                <span className={style.DescriptionBalance}>{t("bid")} </span>
                                                <span
                                                    className={style.DescriptionBalance}>{betValue.toFixed(2)} Usdt</span>
                                            </>}
                                        {(bet && conclusionBetBtn) &&
                                            <>
                                                <span className={style.DescriptionBalance}>{t("take")}</span>
                                                <span className={style.DescriptionBalance}>
                                                     {balanceChanging && balanceChanging.toFixed(2)} Usdt
                                                 </span>
                                            </>
                                        }

                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${style.Rate_blockContainer} ${bet2 && style.ErrRateBlock} ${conclusionBetBtn2 && style.ConcRateBlock}`}>
                            <div className={style.ModeRateBlock}>
                                <div className={style.Mode}>Bet</div>
                                <div className={style.Mode}>Auto</div>
                            </div>
                            <div className={`${style.Rate_block} `}>
                                <div className={style.Rate_counter_block}>
                                    <div className={style.Rate_counter_top}>
                                        <button className={style.Btn_dec}
                                                disabled={bet2 || disabledRateBtn2}
                                                onClick={() => addRate2('-0.1')}>-
                                        </button>
                                        <div
                                            className={`${style.Count_value_block} ${(bet2 || disabledRateBtn2 || disabledBetBtn2) && style.Count_valueDisabled}`}>
                                             <span className={`${style.Count_value} `}>
                                                 {betValue2}
                                             </span>
                                        </div>
                                        <button className={style.Btn_inc}
                                                disabled={bet2 || disabledRateBtn2}
                                                onClick={() => addRate2('0.1')}>
                                        </button>
                                    </div>
                                    <div className={style.Rate_counter_bottom}>
                                        <button className={style.Btn_add_rate} id="btn50"
                                                disabled={bet2 || disabledRateBtn2}
                                                onClick={() => addRate2('1')}>10
                                        </button>
                                        <button className={style.Btn_add_rate} id="btn100"
                                                disabled={bet2 || disabledRateBtn2}
                                                onClick={() => addRate2('2')}>25
                                        </button>
                                        <button className={style.Btn_add_rate} id="btn250"
                                                disabled={bet2 || disabledRateBtn2}
                                                onClick={() => addRate2('5')}>50
                                        </button>
                                        <button className={style.Btn_add_rate} id="max"
                                                disabled={bet2 || disabledRateBtn2}
                                                onClick={() => addRate2('10')}>100
                                        </button>
                                    </div>
                                </div>
                                <div className={style.Rate_block_btn_wrapper}>
                                    <button className={`${style.Rate_block_btn} 
                                         ${bet2 && style.Rate_block_btn_cancelled} 
                                         ${conclusionBetBtn2 && style.Rate_block_btn_conclusion}
                                         ${(stopGame || disabledBetBtn2) && style.DisabledBtn}`}
                                            disabled={disabledBetBtn2}
                                            onClick={makeBet2}>
                                        {bet2 && !conclusionBetBtn2 && <span>{t("cancel")}</span>}
                                        {!bet2 &&
                                            <>
                                                <span className={style.DescriptionBalance}>{t("bid")} </span>
                                                <span
                                                    className={style.DescriptionBalance}>{betValue2.toFixed(2)} Usdt</span>
                                            </>}
                                        {(bet2 && conclusionBetBtn2) &&
                                            <>
                                                <span className={style.DescriptionBalance}>{t("take")}</span>
                                                <span className={style.DescriptionBalance}>
                                                     {balanceChanging2 && balanceChanging2.toFixed(2)} Usdt!!!!!
                                                 </span>
                                            </>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                        <a href={data?.ref_link} className={style.Take_win_btn} target={'_blank'}>
                            {t("takeProfit")}
                        </a>
                    </div>


                </div>
            }
        </>

    )
}