import style from "../gameField/GameFeldStyle.module.css";
import {useEffect, useState} from "react";
import {ValueX} from "../valueX/ValueX";

type ProgressBarType = {
    setRoadMove: (tr: boolean) => void
    setStartFly: (tr: boolean) => void
    disabledBetBtn: boolean
    disabledBetBtn2: boolean
    setProgress: (tr: any) => void
    progress: number
    bet: boolean
    bet2: boolean
    startFly: boolean
    setConclusionBetBtn: (tr: boolean) => void
    setConclusionBetBtn2: (tr: boolean) => void
    setDisabledBetBtn: (tr: boolean) => void
    setDisabledBetBtn2: (tr: boolean) => void
    setDisabledRateBtn: (tr: boolean) => void
    setDisabledRateBtn2: (tr: boolean) => void
    createNewGame: () => void
}

export const ProgressBar = (props: ProgressBarType) => {


    useEffect(() => {
        if (props.progress !== 0) {
            setTimeout(() => {
                props.setProgress((tr: any) => tr - 4)
            }, 120)
        } else if (props.progress <= 2) {
            if (!props.bet) {
                ('bet')
                props.setDisabledBetBtn(!props.disabledBetBtn)
            }
             if (!props.bet2) {
                ('!bet2')
                props.setDisabledBetBtn2(!props.disabledBetBtn2)
            }
            if (props.bet) {

                props.setConclusionBetBtn(true)

            }
            if (props.bet2) {

                props.setConclusionBetBtn2(true)
            }
            props.createNewGame()
            props.setDisabledRateBtn(true)
            props.setDisabledRateBtn2(true)
            props.setRoadMove(true)
            props.setStartFly(!props.startFly)

        }
    }, [props.progress, props.setProgress]);


    return (
        <div className={`${style.Loader} ${props.progress <= 2 && style.Loader_none}`}>
            <div className={style.Loader_line} style={{width: `${props.progress}px`}}></div>
        </div>
    )
}