import style from "./ModalStyle.module.css";
import chrystal from "../../assets/imgs/chrystall.svg";
import {useTranslation} from "react-i18next";

type ModalType = {
    setOpenWinModal: (tr: boolean) => void
    balanceChanging: number | undefined | null
    link: string | undefined
    takenMultiplier: number
    type: '1' | '2'
}
export const Modal = (props: ModalType) => {

    const returnToGame = () => {
        props.setOpenWinModal(false)
    }
    const {t} = useTranslation();


    return (

        <div className={`${style.Little_modal_container} ${props.type === '2' && style.Little_modal_container2}`}>
            <div>
                <p className={style.Title_little_modal}>
                    {t("youHaveEarned")}!
                </p>
                <div>
                    {props.takenMultiplier}x
                </div>
            </div>
            <div className={style.Little_right_block_title}>
                <p>
                    Ваш выигрыш
                </p>
                <p> {props.balanceChanging}</p>
            </div>
        </div>
    )
}