import style from './ValueX.module.css';
import {useState} from "react";

type ValueXType = {
    valueX: string
    flymen: boolean
}

export const ValueX = (props: ValueXType) => {


    return (
        <div className={`${style.Current_value_x}`}>
            <p className={`${style.Value_x} ${props.flymen && style.Value_xRed}`}>{props.valueX}x</p>
        </div>
    )
}