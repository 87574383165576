import style from "./GameFeldStyle.module.css";
import plane from "../../assets/imgs/PLAne.svg";
import line from "../../assets/imgs/polygone.svg";
import scaleVertically from "../../assets/imgs/scaleVertically.svg";
import scaleHorizontal from "../../assets/imgs/scaleHorizontally.svg";
import {ProgressBar} from "../progressBar/ProgressBar";
import {ReactComponent as Logo} from '../../assets/imgs/logo_Aviator.svg'
import {ValueX} from "../valueX/ValueX";
import {CreateGameType} from "../types/types";
import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";

type GameFieldType = {
    disabledBetBtn: boolean
    disabledBetBtn2: boolean
    bet2: boolean
    bet: boolean
    setDisabledBetBtn: (tr: boolean) => void
    setDisabledBetBtn2: (tr: boolean) => void
    setConclusionBetBtn: (tr: boolean) => void
    setConclusionBetBtn2: (tr: boolean) => void
    setDisabledRateBtn: (tr: boolean) => void
    setDisabledRateBtn2: (tr: boolean) => void
    setStopGame: (tr: boolean) => void
    setOpenWinModal: (tr: boolean) => void
    betValue: number
    addBalance: (multiplier: number, balance_change: number | undefined) => void
    stopGame: boolean
    openWinModal: boolean
    setBalance: (tr: number) => void
    balance: number | undefined
    countdown_start: (newGameData: CreateGameType) => void
    roadMove: boolean
    setRoadMove: (tr: boolean) => void
    startFly: boolean
    progress: number
    flyMen: boolean
    setProgress: (tr: number) => void
    setStartFly: (tr: boolean) => void
    valueX: string
    createNewGame: () => void

}

function isMobile() {
    return ['Android', 'iPhone Simulator', 'iPod', 'iPad',
        'iPhone', 'iPod', 'Opera Mini', 'IEMobile'].includes(navigator.platform)

}

function isMobile2() {
    var check = false;
    (function (a) {
        // @ts-ignore
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
            check = true;
    })(navigator.userAgent || navigator.vendor || window);
    return check;
};

export const GameField = (props: GameFieldType) => {
    // const [type, setType] = useState(false)

    const {t} = useTranslation();

    const [deviceType, setDeviceType] = useState('');

    useEffect(() => {
        const userAgent = window.navigator.userAgent;

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
            setDeviceType('Mobile');
        } else if (/Mac|iPad|iPhone|iPod/.test(navigator.platform)) {
            setDeviceType('iOS');
        } else if (/Windows Phone|Windows NT|Windows/i.test(userAgent)) {
            setDeviceType('Windows');
        } else if (/Linux|X11/i.test(userAgent)) {
            setDeviceType('Linux');
        } else {
            setDeviceType('Desktop');
        }

    }, []);


    return (
        <div className={`${style.Wrapper} ${props.roadMove && style.WrapperMove}`}>
            <div className={`${style.Stars_img} ${props.roadMove && style.Stars_imgMove}`}/>
            <div className={`${style.Main_content_block}`}>
                {/*<div className={style.Main_left_line}></div>*/}
                <div className={style.Container_for_vertical}>
                    <div className={`${style.Wr_vert} ${props.roadMove && style.Wr_vertMoving}`}>
                        <img src={scaleVertically} className={style.Vertical}/>
                    </div>
                </div>
                <div className={`${style.Main_content} ${props.roadMove && style.Main_contentMove}`}>
                    {
                        !props.startFly && <div className={style.Main_inner_content}>
                            {/*<MainLogo/>*/}
                            <div className={style.LogoBlock}>
                                <Logo/>
                            </div>
                            <h1 className={style.Title_main}> {t("waiting")}</h1>
                            <ProgressBar setRoadMove={props.setRoadMove}
                                         bet={props.bet}
                                         bet2={props.bet2}
                                         setStartFly={props.setStartFly}
                                         progress={props.progress}
                                         startFly={props.startFly}
                                         setProgress={props.setProgress}
                                         disabledBetBtn={props.disabledBetBtn}
                                         disabledBetBtn2={props.disabledBetBtn2}
                                         setDisabledBetBtn={props.setDisabledBetBtn}
                                         setDisabledBetBtn2={props.setDisabledBetBtn2}
                                         setDisabledRateBtn={props.setDisabledRateBtn}
                                         setDisabledRateBtn2={props.setDisabledRateBtn2}
                                         setConclusionBetBtn={props.setConclusionBetBtn}
                                         setConclusionBetBtn2={props.setConclusionBetBtn2}
                                         createNewGame={props.createNewGame}
                            />
                        </div>
                    }


                    <div className={`${style.Main_inner_content2} ${props.startFly && style.Main_inner_contentFly} `}>
                        <div className={`${style.Example} ${props.startFly && style.ExampleFly} 
                                     ${(props.startFly && deviceType === 'Desktop') && style.ExampleFly2} 
                                      ${props.flyMen && style.ExampleFlyOutside}
                                         `}>
                            {/*<img src={line} alt="" className={style.ImgggLine}/>*/}
                            <div
                                className={`${style.Men_block} ${props.startFly && style.Men_blockMove} 
                                ${deviceType !== 'Mobile' && style.OtherPositionMen}
                                ${(props.startFly && deviceType !== 'Mobile') && style.MenDekstop} 
                                 ${props.flyMen && style.Men_fly}`}>
                                <img src={plane} alt="" className={style.Plane}/>
                                <img src={plane} alt=""
                                     className={`${style.Screw}  ${deviceType !== 'Mobile' && style.OtherScrew}`}/>
                            </div>
                        </div>
                    </div>
                    <div className={style.FlewWr}>
                        {
                            props.flyMen && <p className={style.Flew}>{t("flewAway")}</p>
                        }
                        {
                            props.valueX && <ValueX valueX={props.valueX} flymen={props.flyMen}/>
                        }
                    </div>
                    <div className={style.Container_for_horisont}>
                        <div className={`${style.Wr_for_horisont} ${props.roadMove && style.Wr_for_horisontMoving}`}>
                            <img src={scaleHorizontal} alt="" className={style.Horisont}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}